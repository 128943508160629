import React from "react"

import appstart from "../images/app_start.png"
import appmaster from "../images/app_master.png"
import appsetting from "../images/app_setting.png"
import Button from "../components/button"
import ButtonSecondary from "../components/button-secondary"
import SectionHeader from "./section-header"
import {Link} from "gatsby"
import { Reveal , Animation} from "react-genie"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

const About = () => (
  <div className="about" style={{ 
      padding: "4rem 0 0 0",
      textAlign: "center",
      backgroundColor: "#f2f2f2"
    }}>
    <h2 style={{padding: "2rem"}}>
      バーコードde実棚<span style={{fontSize: "1rem", fontWeight: 200, marginLeft: "5px"}}>とは</span>
    </h2>
    <p>棚卸に関するこんな悩みはありませんか？</p>
    <div className="about-worries-container">
      <div className="about-worries">
        <ul>
          <li><FontAwesomeIcon icon={faCheck} style={{marginRight:15, color:"skyblue"}}/>時間がかかる。</li>
          <li><FontAwesomeIcon icon={faCheck} style={{marginRight:15, color:"skyblue"}}/>検算が面倒</li>
          <li><FontAwesomeIcon icon={faCheck} style={{marginRight:15, color:"skyblue"}}/>人件費がバカにならない　など...</li>
        </ul>
      </div>
    </div>
    <p>これらを解決するのが『バーコードde実棚』です！</p>
    <div style={{display:"flex", justifyContent:"center", flexWrap:"wrap", marginBottom: 80}}>
      <Reveal animation={Animation.FadeIn} delay={200}>
        <div style={{padding:"1rem"}}>
          <img src={appmaster} alt="アプリマスタ画面"/>
        </div>
      </Reveal>
      <Reveal animation={Animation.FadeIn}>
        <div className="centerImg">
          <img src={appstart} alt="アプリトップ画面"/>
        </div>
      </Reveal>
      <Reveal animation={Animation.FadeIn} delay={400}>
        <div style={{padding:"1rem"}}>
          <img src={appsetting} alt="アプリ設定画面"/>
        </div>
      </Reveal>
    </div>
    <div class="message" style={{marginBottom:"10vh"}}>
        <p>
          棚卸で困っているのはアナタだけではありません！
        </p>
        <p>
          はじめまして。<br/>
          バーコードde実棚、開発者の（株）Barproです。
        </p>
        <p>
          私たちも、店舗での実棚卸に毎回悩んできました。<br/>
          閉店してお客様がいなくなってからでないと棚卸はできません。<br/>
          スタッフにも残業してもらわなくてはならず、検算が合うまで帰れず、遅くなってスタッフを帰した後でも一人で黙々と作業する……期に一度、長時間拘束され、体力的にも辛く、人件費もかさみます。<br/>
          必要性はわかっていますが「ムダ」「面倒」という思いがずっとありました。<br/>
          どうにかできないか？<br/>
          と長年考えて考えて、ようやく実現したのが『バーコードde実棚』なのです。
        </p>
        <p>
          もともと、このアプリは販売するために作ったのではありません。
        </p>
        <p>
          自分たちで使うために作りました。<br/>
          本当に棚卸に困っていたからです。従業員たちも、なぜこんな大変なことをしなくてはならないのか、と不満を感じていたと思います。<br/>
          考えたのは、今自分たちの手元にあるものを活用すること、そして、特に時間がかかる検算を、人が作業しなくても良いようにすること。<br/>
          そこで、普及してきたスマホを使うことを思いつき、検算を手動にしないようにするには、どうすることが一番効率的かを考えていきました。<br/>
          アプリが出来上がったのは、最初の構想から8年後のことです。初めて使った時の衝撃は忘れられません。<br/>
          「今までの棚卸は何だったんだ……！」<br/>
          半分以下の時間で終わってしまったからです。<br/><br/>

          そして、こんな風に棚卸が楽に終わることを、多くの方に実感していただきたいと思うようになりました。<br/>
          毎回辛い棚卸をされているすべての方に、私たちと同じ衝撃を体験していただきたいのです。
        </p>
    </div>
    <div style={{backgroundColor: "#333", color:"#f2f2f2", paddingBottom: "2rem", width:"100%"}}>
      <Reveal className="about-point" animation={Animation.FadeIn} >
        <h3 style={{padding:"4rem 0 3rem 0"}}>iPhone、iPadで。<br />誰でも簡単に実地棚卸。</h3>
        <ul style={{display: "flex", justifyContent:"center",  flexWrap:"wrap",listStyleType: "none", margin:"0"}}>
          <li>
            <h4><strong>1.バーコードをスキャン</strong></h4>
            <p>スマホカメラ機能でバーコードを読み取ります。事前に商品情報を入れているとそのまま反映されます。</p>
          </li>
          <li>
            <h4><strong>2.在庫数を入力して保存</strong></h4>
            <p>バーコードを読み取る毎に在庫数を数えて入力します。その動作の繰り返しで簡単に棚卸ができます。</p>
          </li>
          <li>
            <h4><strong>3.棚卸データを確認</strong></h4>
            <p>棚卸したデータを確認し、修正も可能です。販売価格、仕入価格の情報を入力していれば販売価格合計、仕入価格合計も表示されます。</p>
          </li>
        </ul>
      </Reveal>
      <div className="three-buttons" style={{ justifyContent:"space-around", maxWidth:700, margin:"0 auto"}}>
        <Reveal delay="100" style={{margin:10}}>
          <Link
            to="/usage"
            style={{
              color: `#333`,
              textDecoration: `none`,
            }}
          >
            <ButtonSecondary>
              使い方をもっと見る
            </ButtonSecondary>
          </Link>
        </Reveal>
        <Reveal delay="100" style={{margin:10}}>
          <a href="https://apps.apple.com/app/id1509177299/" target="_blank" style={{ color: "white" }}>
            <Button>
              無料ではじめる
            </Button>
          </a>
        </Reveal>
        <Reveal delay="100" style={{margin:10}}>
          <a href="https://tanaoroshi.barpro.co.jp/" target="_blank" style={{ color: "white" }}>
              <Button>
                有料会員になる
              </Button>
          </a>
        </Reveal>
      </div>
    </div>
  </div>
)

export default About
