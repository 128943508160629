import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Header from "../components/header";
import Hero from "../components/hero";
import About from "../components/about";
import Feature from "../components/feature";
import Benefit from "../components/benefit";
import CallToAction from "../components/cta";
import RequiredSpec from "../components/required-spec";
import Announcement from "../components/announcement";


import CustomerVoice from "../components/customer-voice";
import Question from "../components/question";


import {RevealGlobalStyles} from "react-genie";
import FinalMessage from "../components/final-message";

const IndexPage = () => (
  <Layout>
    <RevealGlobalStyles />
    <SEO />
    <Header siteTitle="バーコードde実棚"/>
    <Hero />
    {/* <Announcement /> */}
    <About />
    <Feature />
    <Benefit />
    <CallToAction />
    <CustomerVoice />
    <RequiredSpec />
    <Question />
    <FinalMessage />
  </Layout>
)

export default IndexPage
