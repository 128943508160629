import React from "react"

import PriceTable from "./price-table"
import SectionHeader from "./section-header"
import Button from "./button"

const priceHeaderStyle = {
  alignItems: "center",
  minHeight: 90,
  textAlign: "center",
  fontSize: "1rem",
}

const specTableListItemStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: 36,
  fontSize: "0.75rem",
  margin: 0
}

const priceHeaderStrongStyle = {
  fontSize: "2rem",
}

const priceTableGeneralStyle = `ul.features li:nth-child(odd){background-color: #fdfdfd;}`;

const RequiredSpec = () => (
  <div className="required-spec" style={{ padding: "4rem 1rem", textAlign: "center"}}>
    <div className="required-spec-container">
      <SectionHeader
        title="推奨環境"
        description=""
      />
      <div style={{marginBottom:40}}>
        <div style={{display:"flex", flexWrap:"wrap", justifyContent:"center"}}>
          <table border="1" style={{textAlign:"left"}}>
            <tr>
              <td rowSpan="2" style={{backgroundColor:"lightgrey"}}>対応OS</td>
              <td>iOS 12.0以上</td>
            </tr>
            <tr>
              <td>Android　計画中</td>
            </tr>
            <tr>
            <td style={{backgroundColor:"lightgrey"}}>動作確認済みバーコードリーダー</td>
              <td>
                Inateck BCST-70、RIOTEC iDC9277A<br/><br/>
                ※RIOTEC iDC9277Aは、弊社で販売も行っております。<br/>
                <a href="https://forms.gle/9AzsHoN9UfSBoqQDA">RIOTEC iDC9277A 購入フォーム</a>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <a href="https://apps.apple.com/app/id1509177299/" target="_blank" style={{ color: "white" }}>
        <Button>
          無料ではじめる
        </Button>
      </a>
      <a href="https://tanaoroshi.barpro.co.jp/" target="_blank" style={{ color: "white" }}>
        <Button>
          有料会員になる
        </Button>
      </a>
    </div>
  </div>
)

export default RequiredSpec
