import React from "react"

import appstart from "../images/app_start.png"
import appmaster from "../images/app_master.png"
import appsetting from "../images/app_setting.png"
import Button from "./button"
import ButtonSecondary from "./button-secondary"
import SectionHeader from "./section-header"
import {Link} from "gatsby"
import { Reveal , Animation} from "react-genie"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import tanalog_banner from "../images/tanalog_banner.jpg"

const FinalMessage = () => (
  <div className="final-message" style={{ 
      padding: "4rem 0 4rem 0",
      textAlign: "center"
    }}>
    <div class="message">
      <h2>最後に</h2>
        <p>
          棚卸が必要で大切なことはわかっています。<br/>
          ですが、様々なコストをかけてまでやるものなのか？その時間を、商品やお客様に使いたい、と思ってしまうのも人情です。<br/>
          お客様を大切にすることで、私たちも良くなっていくことは皆知っています。<br/>
          『バーコードde実棚』をご利用いただき、やるべきことを最短の時間で終わらせ、必要なことに力を集中させ、効率のよい店舗経営のお手伝いをさせていただければと思います。<br/>
        </p>
        <p style={{textAlign:"right"}}>
          （株）Barpro
        </p>
    </div>
    <br/>
    <br/>
    <a href="http://tanalog.barpro.co.jp/" target="_blank">
      <img src={tanalog_banner} alt="tanalog"/>
    </a>
  </div>
)

export default FinalMessage
