import React from "react"

import SectionHeader from "./section-header"
import Point from "../components/point";
import ButtonStartOnly from "../components/button-start-only";
import featureimg2 from "../images/feature_img2.jpg";
import featureimg3 from "../images/feature_img3.jpg";

import { faClock, faClipboardCheck } from '@fortawesome/free-solid-svg-icons'
import { Reveal , Animation} from "react-genie"

const Benefit = (props) => (
  <div className="benefit" style={{ 
      padding: "4rem 0 0 0",
      backgroundColor: "#f2f2f2",
      textAlign:"center"
    }}>
    <div className="benefit-container">
      <SectionHeader
          title="特徴・メリット"
          // description=""
        />
      <Point pointTitle="大幅な時間短縮（65%短縮）" pointicon={faClock} imgsrc={featureimg2} description={["検算不要", "数量を入力するだけ", "複数端末で作業可能"]} />
      <Point pointTitle="会計価格が一目でわかる" pointicon={faClipboardCheck} imgsrc={featureimg3} description={["仕入価格", "販売価格"]} />
      <ButtonStartOnly extensionhref="/merit" />
    </div>
  </div>
)

export default Benefit
