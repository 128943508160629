// components/Announcement.js
import React from "react";

const Announcement = () => (
  <div className="announcement" style={{ 
    padding: "4rem 0 0 0",
    textAlign: "center",
    backgroundColor: "#f2f2f2"
    }}>
    <h2 style={{padding: "2rem"}}>
    お知らせ
    </h2>
    <div class="message" style={{marginBottom:"10vh"}}>
      <h4>[2023/10/8]　iOS 17以降で正常に動作しないことが確認されております</h4>
      <p>
        現在、最新のiOSバージョンにてバーコードde実棚アプリが正常に動作しないことが確認されております。<br/>
        この問題については認識しており、対策を検討中ですが、具体的な解決の時期は現時点では未定となっております。<br/>
        お客様には大変ご迷惑をお掛けいたしますが、アプリをご使用の際は、前のバージョンのiOSをご利用いただけますと幸いです。<br/>
      </p>
    </div>
  </div>
);

export default Announcement;
