import React from "react"
import PropTypes from "prop-types"

import Button from "../components/button"
import keyVisual from "../images/kv.jpg"

import { Reveal } from "react-genie"

const Hero = ({ siteTitle }) => (
  <div
    style={{
      backgroundImage: `url(${keyVisual})`,
      backgroundSize: "cover",
      backgroundPosition: "center"
    }}
  >
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        padding: "4rem 1rem",
        background:"rgba(0, 0, 0, .3)"
      }}
    >
      <div
        style={{
          position: "relative",
          top: 0,
          height: "100vh",
          width: "100vw",
        }}
      >
        <div className="hero-absolute-div">
          <Reveal>
            <div style={{ color: 'yellow' }}>
              <h2>小売店をお助けするアプリ</h2>
            </div>
          </Reveal>
          <Reveal>
            <h1>バーコード<br />de<br />実棚</h1>
          </Reveal>
          <Reveal delay="100">
            <p>
              スマホで棚卸があっという間に終わる！
            </p>
          </Reveal>
          <Reveal  delay="200">
            <a href="https://apps.apple.com/app/id1509177299/" target="_blank" style={{ color: "white"}}>
              <Button>
                無料ではじめる
              </Button>
            </a>
          </Reveal>
          <Reveal  delay="200">
            <a href="https://tanaoroshi.barpro.co.jp/" target="_blank" style={{ color: "white"}}>
              <Button>
                有料会員になる
              </Button>
            </a>
          </Reveal>
        </div>

        {/* <div className="hero-absolute-div3">
          <Reveal delay="300">
            <p>
              小売店をお助けするアプリ
            </p>
          </Reveal>
        </div> */}
        <div className="hero-absolute-div2">
          <Reveal delay="300">
            <p>
              スマホのカメラで商品のバーコードを読み取るだけ！
            </p>
          </Reveal>
        </div>
      </div>
    </div>
  </div>
)

Hero.propTypes = {
  siteTitle: PropTypes.string,
}

Hero.defaultProps = {
  siteTitle: ``,
}

export default Hero
